import { useEffect, useState } from 'react'
import { Warning, HorizontalLine, NavView } from '../Components/home_elements'
import { useLocation } from 'react-router-dom'
import {
  isUser,
  getSingleUser,
  getkratos,
  updateUserPayment,
  AddHistory
} from '../firebase'
import {
  FaDollarSign,
  FaArrowRight,
  FaBitcoin,
  FaMonero,
  FaEthereum,
} from 'react-icons/fa'
import { PaymentAPI, CheckPaymentAPI } from '../Data/data'
import Icon from 'react-crypto-icons'
import { SvgIcon } from '@material-ui/core'
const Payment = () => {
  const [username, setUsername] = useState(localStorage.getItem('username'))
  const [useremail, setUseremail] = useState('')
  const [balance, setBalance] = useState(localStorage.getItem('balance'))
  const [uid, setUid] = useState('')
  const [password, setPassword] = useState('')
  const [isLogin, setIslogin] = useState(false)

  document.title = "Activate Account - Wallet - Benumb CC Shop | #1 Easy Cashout CC &amp; Fulls Shop"

  useEffect(() => {
    isUser().then((v) => setIslogin(v))
  }, [])

  useEffect(() => {
    getSingleUser().then((v) => {
      //alert(v)
      setUsername(v.data().name)
      setUseremail(v.data().email)
      setBalance(v.data().balance)
      setPassword(v.data().password)
      setUid(v.data().id)
    })
  }, [])

  return (
    <div className="flex flex-col justify-center darkbg w-full ">
      <div className="flex flex-col justify-center  w-full px-4 lg:px-0">
        <Warning />
        <HorizontalLine />
        <div className="flex w-full justify-center flex-col items-center  lightbg pt-10">
          <NavView name={username} balance={balance} islogin={isLogin} />
          <br />
        </div>
        <Activationdesc balance={balance} />
      </div>
      <div className="py-6 bg-white text-center">
        <p>
          Deposits into your market wallet will appear after reaching 2
          confirmations on the network.{' '}
        </p>
        <br />
        <p>
          This typically takes 15 minutes for Bitcoin and 30 minutes for Monero.
        </p>
        <br />
        <br />
      </div>
    </div>
  )
}

const Activationdesc = ({ balance }) => {
  const [hasdata, setHasdata] = useState(false)
  const [payment, setPayment] = useState([])
  const data = []
  const code = localStorage.getItem('code')
  const getprice  = useLocation()
 
  useEffect(() => {
    getkratos().then((v) => {
      code !== null
        ? CheckPaymentAPI(v.data().kratos, code)
            .then((res) => res.json())
            .then((result) => {
              setPayment([result])
              setHasdata(true)
              console.log([result][0].data.code)
              localStorage.setItem('code', [result][0].data.code.toString())
              MakePaymentDecision(payment)
            })
            .catch((error) => console.log(error))
        : PaymentAPI(v.data().kratos, getprice.state.pr)
            .then((res) => res.json())
            .then((result) => {
              setPayment([result])
              setHasdata(true)
              console.log([result][0].data.code)
              localStorage.setItem('code', [result][0].data.code.toString())
            })
            .catch((error) => console.log(error))
    })
  }, [])
  return (
    <div className="flex flex-col items-center gap-6 w-full xl:w-3/5 m-auto py-16">
      <p className="darktextgreen text-xl font-extrabold">
        Payment Deposit Required
      </p>
      <p>
        You need <strong className="font-extrabold text-xl">${getprice.state.pr} USD</strong>{' '}
        to be able to buy this card. Your account is insufficient <strong className="font-extrabold text-xl"></strong>{' '}
        to complete this process
      </p>
      <p>
        <strong className="font-extrabold text-xl">
          This is not a deposit, it's the cost of the card just pay for what you bought.{' '}
        </strong>{' '}
        You will be able to purchase the card immediately after it has
        received 2 confirmations on the network.
      </p>
      <p className="text-center">
        We accept BTC, ETH, DAI, DOGE, USDT, BCH. You can use either of the address' below to
        activate your account. You may also deposit any amount you like, it just
        has to be over the minimum price of cards up for sale in order to buy your card.</p>
      <p>
        If you have any difficulties, please feel free to contact us on our
        support page.
      </p>
      <div className="bg-white w-full mt-6 border-black border-1">
        <div className="pt-8 text-center">
          <p className="darktextgreen text-xl">Benumb Wallet Balance</p>
          <h1 className="darktextgreen font-extrabold text-2xl">{balance}</h1>
          <br />
          <hr className="border-black" />
          <div className="p-2 text-center darkbg">
            <p className="text-black">
              Last updated: {localStorage.getItem('today')}
            </p>
          </div>
        </div>
      </div>
      {!hasdata ? (
        <div
          className="w-full border-black border-1"
          style={{ background: '#2C3E54' }}
        >
          <div className="text-center items-center">
            <h1 className="py-4 text-white font-extrabold text-lg">
              Loading Payment Address, Plese wait
            </h1>
            <hr className="border-black" />
          </div>
        </div>
      ) : null}
      {hasdata ? (
        <>
        {/* {console.log(payment[0].data)} */}
          <PaymentAddress
            title="Bitcoin"
            bg="#F8A23A"
            address={payment[0].data.addresses.bitcoin}
            amount={
              parseFloat(payment[0].data.pricing.bitcoin.amount).toFixed(4) + ' '+
              payment[0].data.pricing.bitcoin.currency+ ' '+
              `($${parseInt(payment[0].data.pricing.local.amount)})`
              
            }
            tokenicon={<FaBitcoin color="#ffffff" size={20} />}
            isNextline={false}
          />
          <PaymentAddress
            title="Etherum"
            bg="#2C3E54"
            address={payment[0].data.addresses.ethereum}
            amount={
              parseFloat(payment[0].data.pricing.ethereum.amount).toFixed(4) + ' '+
              payment[0].data.pricing.ethereum.currency+ ' '+
              `($${parseInt(payment[0].data.pricing.local.amount)})`
            }
            tokenicon={<FaEthereum color="#ffffff" size={20} />}
            isNextline={false}
          />
          <PaymentAddress
            title="Tether"
            bg="#259C77"
            address={payment[0].data.addresses.tether}
            amount={
              parseFloat(payment[0].data.pricing.tether.amount).toFixed(4) + ' '+
              payment[0].data.pricing.tether.currency+ ' '+
              `($${parseInt(payment[0].data.pricing.local.amount)})`
            }
            tokenicon={<FaEthereum color="#ffffff" size={20} />}
            isNextline={false}
          />
          <PaymentAddress
            title="Dai"
            bg="#E9A334"
            address={payment[0].data.addresses.dai}
            amount={
              parseFloat(payment[0].data.pricing.dai.amount).toFixed(4) + ' '+
              payment[0].data.pricing.dai.currency+ ' '+
              `($${parseInt(payment[0].data.pricing.local.amount)})`
            }
            tokenicon={<FaEthereum color="#ffffff" size={20} />}
            isNextline={false}
          />
          <PaymentAddress
            title="Litecoin"
            bg="#315895"
            address={payment[0].data.addresses.litecoin}
            amount={
              parseFloat(payment[0].data.pricing.litecoin.amount).toFixed(4) + ' '+
              payment[0].data.pricing.litecoin.currency+ ' '+
              `($${parseInt(payment[0].data.pricing.local.amount)})`
            }
            tokenicon={<FaEthereum color="#ffffff" size={20} />}
            isNextline={false}
          />
          <PaymentAddress
            title="Doge Coin"
            bg="#B19823"
            address={payment[0].data.addresses.dogecoin}
            amount={
              parseFloat(payment[0].data.pricing.dogecoin.amount).toFixed(4) + ' '+
              payment[0].data.pricing.dogecoin.currency+ ' '+
              `($${parseInt(payment[0].data.pricing.local.amount)})`
            }
            tokenicon={<FaEthereum color="#ffffff" size={20} />}
            isNextline={false}
          />
          <PaymentAddress
            title="Bitcoin Cash"
            bg="#86B94D"
            address={payment[0].data.addresses.bitcoincash}
            amount={
              parseFloat(payment[0].data.pricing.bitcoincash.amount).toFixed(4) + ' '+
              payment[0].data.pricing.bitcoincash.currency+ ' '+
              `($${parseInt(payment[0].data.pricing.local.amount)})`
            }
            tokenicon={<FaEthereum color="#ffffff" size={20} />}
            isNextline={false}
          />
        </>
      ) : (
        <div></div>
      )}
    </div>
  )
}

const PaymentAddress = ({
  title,
  bg,
  address,
  amount,
  tokenicon,
  isNextline,
}) => {
  const [copyaddress, setCopyaddress] = useState(false)
  const [copyamount, setCopyamount] = useState(false)
  return (
    <div className="w-full border-black border-1" style={{ background: bg }}>
      <div className="text-center items-center">
        <h1 className="py-4 text-white font-extrabold text-lg">
          Activate with {title}
        </h1>
        <hr className="border-black" />
        <div className="p-8 text-center darkbg">
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col items-start gap-4 lg:w-2/4">
              <p className="text-lg">Deposite Amount</p>
              <div
                className="p-4 bg-white border-l-8 w-full"
                style={{ borderLeftColor: '#2C3E54' }}
              >
                {isNextline ?? <br />}
                <p className="text-lg font-bold text-left">Send {amount}</p>
              </div>
              <div
                className="flex justify-between items-center p-4  w-full"
                style={{ background: '#2C3E54' }}
              >
                <FaDollarSign color="#ffffff" size={20} />
                <p
                  className="text-sm font-bold text-center w-full text-white"
                  onClick={() => {
                    copy(amount).then((v) => setCopyamount(true))
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {copyamount ? 'Copied' : 'Copy amount'}
                </p>
              </div>
            </div>
            <div className="flex justify-end  mt-8 p-4 lg:w-1/4">
              <FaArrowRight size={30} />
            </div>
            <div className="flex flex-col items-start gap-4 lg:w-full">
              <p className="text-lg">{title} Address</p>
              <div
                className="p-4 bg-white border-l-8 w-full "
                style={{ borderLeftColor: bg }}
              >
                <p
                  className="text-xl font-extrabold text-left "
                  style={{ wordBreak: 'break-all' }}
                >
                  {address}
                </p>
              </div>
              <div
                className="flex justify-between items-center p-4  w-full"
                style={{ background: bg }}
              >
                {tokenicon}
                <p
                  className="text-sm font-bold text-center w-full text-white"
                  onClick={() => {
                    copy(address).then((v) => setCopyaddress(true))
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {copyaddress ? 'Copied' : 'Copy address'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export const copy = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

const MakePaymentDecision = (payment) => {
  const timeline = payment[0].data.timeline
  const payments = payment[0].data.payments
  console.log(payment[0].data.payments)
  if (
    payments.length > 0 &&
    payments.payment_id.status === 'CONFIRMED' &&
    timeline.length > 2
  ) {
    if (
      timeline[2].status === 'COMPLETED' ||
      timeline[2].status === 'RESOLVED'
    ) {
      console.log('Payment completed - Normal Payment Completion')
      PaymentUpdateProcess(payment)
    }
    if (
      timeline.length === 3 &&
      timeline[2].status === 'UNRESOLVED' &&
      timeline[2].context === 'OVERPAID'
    ) {
      console.log('Payment completed - Overpayment')
      PaymentUpdateProcess(payment)
    }
    if (timeline.length === 4 && timeline[0].status === 'RESOLVED') {
      console.log('Payment completed - Resolved')
      PaymentUpdateProcess(payment)
    }
  }

  if (
    timeline.length === 3 &&
    timeline[2].status === 'UNRESOLVED' &&
    timeline[2].context === 'UNDERPAID'
  ) {
    console.log('Payment not fully made')
  }
  return <></>
}

const PaymentUpdateProcess = (payment) => {
  updateUserPayment(parseInt(payment[0].data.pricing.local.amount)).then(
    (v) => {
      localStorage.removeItem('code')
      AddHistory({
        uid: localStorage.getItem('email'),
        date: Date.now().toString(),
        history: `You topped up your account banace for $${payment[0].data.pricing.local.amount}`
      })
    },
  )
}

export default Payment
