import React, { useState } from 'react'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import '../App.css'
import { NavLink } from 'react-router-dom'
import { register , AddHistory} from '../firebase'

const Login = () => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmpass, setConfirmpass] = useState('')
  const [code, setCode] = useState('')

  document.title = "Sign Up - BENUMB CC Shop | #1 Easy Cashout CC & Fulls Shop"

  const [vpass, setVpass] = useState(false)
  return (
    <div className="flex w-full h-screen loginbackground p-2 justify-center">
      <div className="w-full mt-20 lg:w-2/4 xl:w-1/3 flex justify-center">
        <div>
          <img
            className="flex w-72 m-auto"
            src="https://benumb.cards/wp-content/uploads/2022/05/1ptv6440.png"
            alt="Benumb"
          />

          <input
            type="text"
            name="username"
            id="username"
            className="outline:none p-2 w-full logindeco mt-5"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="email"
            name="email"
            id="email"
            className="outline:none p-2 w-full logindeco mt-5"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="flex flex-col relative mt-5 ">
            <input
              type={vpass ? 'text' : 'password'}
              name="pass-1"
              id="pass-1"
              className="outline:none p-2 w-full logindeco relative"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute top-2 bottom-0 right-2"
              onClick={(e) => {
                setVpass(!vpass)
              }}
            >
              {vpass ? (
                <AiOutlineEyeInvisible size={25} />
              ) : (
                <AiOutlineEye size={25} />
              )}
            </div>
          </div>

          <input
            type="password"
            name="pass-2"
            id="pass-2"
            className="outline:none p-2 w-full logindeco mt-5"
            placeholder="Confirm Password"
            onChange={(e) => setConfirmpass(e.target.value)}
          />
          <input
            type="text"
            name="invite-code"
            id="invite-code"
            className="outline:none p-2 w-full logindeco mt-5"
            placeholder="Invite Code (Optional)"
            onChange={(e) => setCode(e.target.value)}
          />

          <button
            className="sbtn p-2 w-full green mt-8 mb-12 font-normal text-lg text-white flex justify-center"
            onClick={() => {
              if (password !== confirmpass) {
                alert('Password do not match')
              } else {
                register(username, email, password).then(() => {
                  localStorage.setItem('email', email)
                  window.location.replace('/members-shop')
                  AddHistory({
                    uid: email,
                    date: Date.now().toString(),
                    history: `You signed up for an account with email ${email} and username ${username}`
                  })
                })
              }
            }}
          >
            Sign Up
          </button>

          <p className="justify-left text-lg">
            Already have an account?{' '}
            <NavLink to="/login">
              {
                <span className="font-bold greentext ">
                  <strong>Log in.</strong>
                </span>
              }
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Login
