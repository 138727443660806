import React from 'react'
import { useEffect, useState } from 'react'
import { Warning, HorizontalLine, NavView } from '../Components/home_elements'
import { isUser, getSingleUser } from '../firebase'
import { Link } from 'react-router-dom'
const Forum = () => {
  document.title = "Forum - Benumb CC Shop | #1 Trusted Carding &amp; Fulls Shop"
  
  const [username, setUsername] = useState(localStorage.getItem('username'))
  const [balance, setBalance] = useState(localStorage.getItem('balance'))
  const [isLogin, setIslogin] = useState(false)
  useEffect(() => {
    isUser().then((v) => setIslogin(v))
  }, [])
  useEffect(() => {
    getSingleUser().then((v) => {
      //alert(v)
      setUsername(v.data().name)
      setBalance(v.data().balance)

      localStorage.setItem('username', v.data().name)
      localStorage.setItem('balance', v.data().balance)
      localStorage.setItem('uid', v.data().id)
    })
  }, [])
  return (
    <div className="flex flex-col justify-center darkbg w-full ">
      <div className="flex flex-col justify-center  w-full px-4 lg:px-0">
        <Warning />
        <HorizontalLine />
        <div className="flex w-full justify-center flex-col items-center  lightbg pt-10">
          <NavView name={username} balance={balance} islogin={isLogin} />
          <div
            className="flex mt-5 w-full h-full pb-4 justify-center"
            style={{ background: '#041926' }}
          >
            <div className="flex flex-col mt-16 lg:flex-row w-full gap-4 xl:w-2/3 lg:justify-center py-1">
              <div className="flex flex-col gap-4 lg:w-4/5 sm:w-full">
                <Posts
                one = {"Does anyone know how to get kratom in Australia?"}
                two = {"June 6, 2022 by"}
                three = {"/u/Tobacco111"}
                four = {"I can’t find any vendors on Abacus, is there anywhere else where kratom is available?"}
                five = {"Guide"}
                six = {"1 Comment"}
                />
                <Posts
                one = {"I logged into abacus and it looks different – have they done an update?"}
                two = {"June 5, 2022 by"}
                three = {"/u/thestairs"}
                four = {"I logged into abacus and it looks different – have they done an update? I got link off their Dread forum. So dont think its a fishing site… I cant se any update news on the forum? I did not buy anything/send money… just browwsing 🙂"}
                five = {"Guide"}
                six = {"Leave a comment"}
                />
                <Posts
                one = {"I need an rc stim that wont pop on on a drug test"}
                two = {"June 5, 2022 by"}
                three = {"/u/anxietyhaver"}
                four = {"my test, tests for meth, amphet, pcp, kratom,opiates benzos shit like that. Anyone got any ideas?"}
                five = {"Guide"}
                six = {"Leave a comment"}
                />
                <Posts
                one = {"[INTEREST CHECK] Medical Fent Pathes"}
                two = {"June 5, 2022 by"}
                three = {"/u/octor"}
                four = {"Hello Would there be a demand for medical fentanyl patches (Sandoz, Actavis, Matrifen, Durogesic) that you can chew, smoke, make spray, inject. Directly from a doctor."}
                five = {"Guide"}
                six = {"Leave a comment"}
                />
                <Posts
                one = {"The CAPTCHA gave me cancer again :("}
                two = {"June 5, 2022 by"}
                three = {"/u/adpersonam"}
                four = {"Fuck me I thought we’ve moved beyond that but I had to get three new identities until I made it through. My small smooth brain isn’t made for that shit."}
                five = {"Guide"}
                six = {"Leave a comment"}
                />
                <Posts
                one = {"What is the dumbest shit you have ever seen a vendor do?"}
                two = {"June 5, 2022 by"}
                three = {"/u/Stellawhite"}
                four = {"I once had a fedex pack arrive with a god damn metal tin full of bars. What the hell are these guys thinking?"}
                five = {"Guide"}
                six = {"Leave a comment"}
                />
                <Posts
                one = {"⚡ DCdutchconnectionUK is BACK LIVE! ⚡"}
                two = {" June 5, 2022 by "}
                three = {"/u/DCdutchconnectionUK"}
                four = {"Hi guys! We are proud to announce that we are back from vacation and ready to accept your orders! ✅ FREE GIFT IN EVERY PACK ✅ SAME DAY SHIPPING BEFORE 3:00 PM ✅ 1ST CLASS DELIVERY ✅ NEW COMPETITIVE PRICES -DC-01 Peruvian Cocaine *BRAND NEW* *INTRO PRICING* -Cannabis Amnesia Haze (Sativa) -Cannabis Stardawg (Hybrid) -250ug … "}
                five = {"Guide"}
                six = {"1 comment"}
                />
                <Posts
                one = {"How Do I get bank logs?"}
                two = {"June 5, 2022 by"}
                three = {"/u/dexter9714"}
                four = {"I am looking for a guide/information on about how do i get bank logs (by bank logs I mean details of people), I looked for courses and got like paid ones and the free ones ain’t that beneficial. So i look forward to you guys."}
                five = {"Guide"}
                six = {"Leave a comment"}
                />
                <Posts
                one = {"Etizolam and A-PHP promo price give away away! We are RCstore, We sell the highest grade of Research Chemicals for the best prices!"}
                two = {"June 5, 2022 by"}
                three = {"/u/RCStore"}
                four = {"We are RCstore, We sell the highest grade of Research Chemicals for the best prices available on the market in Europe. We lab-test all our products regularly. We simply stand for: – High Quality – Good Prices – Fast & Stealth Delivery – Helpful Customer Service We’ll be sending away 100x A-php gram and 100x …"}
                five = {"Guide"}
                six = {"Leave a comment"}
                />
                <Posts
                one = {"Setup a VPN."}
                two = {"June 5, 2022 by "}
                three = {"/u/addy0032"}
                four = {"I want to setup a good VPN that is free. Can someone guide me please?"}
                five = {"Guide"}
                six = {"Leave a comment"}
                />
         
              
              </div>
              <div className="flex flex-col gap-4 lg:w-1/3 sm:w-full">
                <div className="p-10 bg-white flex flex-row">
                  <input
                    type="search"
                    name="search"
                    id=""
                    className="outline-0 p-4 w-full"
                    placeholder="Search ..."
                    style={{ background: '#F7F8F9' }}
                  />
                  <button
                    className="py-4 px-5 flex items-center"
                    style={{ background: '#3F4047' }}
                  >
                    <svg
                      viewBox="0 0 512 512"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#ffffff"
                        d="M208 48c-88.366 0-160 71.634-160 160s71.634 160 160 160 160-71.634 160-160S296.366 48 208 48zM0 208C0 93.125 93.125 0 208 0s208 93.125 208 208c0 48.741-16.765 93.566-44.843 129.024l133.826 134.018c9.366 9.379 9.355 24.575-.025 33.941-9.379 9.366-24.575 9.355-33.941-.025L337.238 370.987C301.747 399.167 256.839 416 208 416 93.125 416 0 322.875 0 208z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-10 bg-white flex flex-col">
                  <p className="text-gray-700">Recent Posts</p>
                  <div className="mt-5 flex flex-col gap-4">
                    <Link
                      to="#"
                      className="underline text-blue-800 text-lg hover:text-black"
                    >
                      Does anyone know how to get kratom in Australia?
                    </Link>
                    <Link
                      to="#"
                      className="underline text-blue-800 text-lg hover:text-black"
                    >
                      I logged into abacus and it looks different – have they
                      done an update?
                    </Link>
                    <Link
                      to="#"
                      className="underline text-blue-800 text-lg hover:text-black"
                    >
                      I need an rc stim that wont pop on on a drug test
                    </Link>
                    <Link
                      to="#"
                      className="underline text-blue-800 text-lg hover:text-black"
                    >
                      [INTEREST CHECK] Medical Fent Pathes
                    </Link>
                    <Link
                      to="#"
                      className="underline text-blue-800 text-lg hover:text-black"
                    >
                      The CAPTCHA gave me cancer again :(
                    </Link>
                  </div>
                </div>
                <div className="p-10 bg-white flex flex-col">
                  <p className="text-gray-700">Recent Comments</p>
                  <div className="mt-5 flex flex-col gap-4">
                    <p>
                      Veza on{' '}
                      <Link
                        to="#"
                        className="underline text-blue-800 text-lg hover:text-black"
                      >
                        Does anyone know how to get kratom in Australia?
                      </Link>
                    </p>
                    <p>
                     
                      <Link
                        to="#"
                        className="underline text-blue-800 text-lg hover:text-black"
                      > Mittie Barron</Link>{' '}
                      on{' '}
                      <Link
                        to="#"
                        className="underline text-blue-800 text-lg hover:text-black"
                      >
                        ⚡ DCdutchconnectionUK is BACK LIVE! ⚡
                      </Link>
                    </p>
                    <p>
                      Paul on 
                      <Link
                        to="#"
                        className="underline text-blue-800 text-lg hover:text-black"
                      >
                         Can Gift cards be canceled?
                      </Link>
                    </p>
                    <p>
                      dontdiebro on{' '}
                      <Link
                        to="#"
                        className="underline text-blue-800 text-lg hover:text-black"
                      >
                        [LFW – MULTI] Phisherman For Hire
                      </Link>
                    </p>
                    <p>
                      dontdiebro on{' '}
                      <Link
                        to="#"
                        className="underline text-blue-800 text-lg hover:text-black"
                      >
                        [LFW – MULTI] Mylar bag not sealed on arrival
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const Posts = ({one, two, three, four, five, six}) =>{
  return (
    <div className="p-10 bg-white">
                  <h1 className="text-black text-5xl">
                      {one}
                  </h1>
                  <p className="text-sm mt-2">
                  {two}
                    <span>
                      <Link
                        to="#"
                        className="underline text-blue-800 hover:text-black"
                      >
                       {three}
                      </Link>
                    </span>
                  </p>
                  <p className="text-lg font-bold mt-6">
                    {four}
                  </p>
                  <div className="mt-5 flex flex-col">
                    <div className="mt-5 flex flex-row gap-2 items-center">
                      <svg
                        viewBox="0 0 512 512"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                      >
                        <path d="M0 112c0-26.51 21.49-48 48-48h110.014a48 48 0 0143.592 27.907l12.349 26.791A16 16 0 00228.486 128H464c26.51 0 48 21.49 48 48v224c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112z"></path>
                      </svg>
                      <Link
                        to="#"
                        className="underline text-blue-800 hover:text-black"
                      >
                       {five}
                      </Link>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <svg
                        viewBox="0 0 512 512"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                      >
                        <path d="M132.838 329.973a435.298 435.298 0 0016.769-9.004c13.363-7.574 26.587-16.142 37.419-25.507 7.544.597 15.27.925 23.098.925 54.905 0 105.634-15.311 143.285-41.28 23.728-16.365 43.115-37.692 54.155-62.645 54.739 22.205 91.498 63.272 91.498 110.286 0 42.186-29.558 79.498-75.09 102.828 23.46 49.216 75.09 101.709 75.09 101.709s-115.837-38.35-154.424-78.46c-9.956 1.12-20.297 1.758-30.793 1.758-88.727 0-162.927-43.071-181.007-100.61z"></path>
                        <path d="M383.371 132.502c0 70.603-82.961 127.787-185.216 127.787-10.496 0-20.837-.639-30.793-1.757-38.587 40.093-154.424 78.429-154.424 78.429s51.63-52.472 75.09-101.67c-45.532-23.321-75.09-60.619-75.09-102.79C12.938 61.9 95.9 4.716 198.155 4.716 300.41 4.715 383.37 61.9 383.37 132.502z"></path>
                      </svg>
                      <Link
                        to="#"
                        className="underline text-blue-800 hover:text-black"
                      >
                        {six}
                      </Link>
                    </div>
                  </div>
                </div>
  )
}
export default Forum
