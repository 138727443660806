import { useEffect, useState } from 'react'
import { Warning, HorizontalLine, NavView } from '../Components/home_elements'
import {
  isUser,
  getSingleUser,
  getkratos,
  updateUserPayment,
  GetCards,
  GetHistory,
} from '../firebase'
import { BiMessageDetail } from 'react-icons/bi'
import { FaEye, FaRecycle, FaDownload } from 'react-icons/fa'
import { copy } from './payment'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
const AccountPage = () => {
  const [username, setUsername] = useState(localStorage.getItem('username'))
  const [useremail, setUseremail] = useState('')
  const [balance, setBalance] = useState(localStorage.getItem('balance'))
  const [uid, setUid] = useState('')
  const [password, setPassword] = useState('')
  const [isLogin, setIslogin] = useState(false)

  document.title = 'Account - BENUMB CC SHOP'

  useEffect(() => {
    isUser().then((v) => setIslogin(v))
  }, [])

  useEffect(() => {
    getSingleUser().then((v) => {
      //alert(v)
      setUsername(v.data().name)
      setUseremail(v.data().email)
      setBalance(v.data().balance)
      setPassword(v.data().password)
      setUid(v.data().id)
    })
  }, [])

  return (
    <div className="flex flex-col justify-center darkbg w-full ">
      <div className="flex flex-col justify-center  w-full px-4 lg:px-0">
        <Warning />
        <HorizontalLine />
        <div className="flex w-full justify-center flex-col items-center  lightbg pt-10">
          <NavView name={username} balance={balance} islogin={isLogin} />
          <br />
        </div>
        <Activationdesc balance={balance} />
      </div>
    </div>
  )
}

const Activationdesc = () => {
  const username = localStorage.getItem('username')
  const email = localStorage.getItem('email')
  const balance = localStorage.getItem('balance')
  const logontime = localStorage.getItem('today')

  const [ismycards, setIsmycards] = useState(false)
  const [mycards, setMycards] = useState([])
  const docz = []

  const [ishistory, setIshistory] = useState(false)
  const [history, setHistory] = useState([])
  const his = []

  useEffect(() => {
    GetCards().then((v) => {
      v.forEach((doc) => {
        var d = doc.data()
        docz.push(d)
        setIsmycards(true)
      })
      setMycards(docz)
    })
  }, [])

  useEffect(() => {
    GetHistory().then((v) => {
      v.forEach((doc) => {
        var d = doc.data()
        his.push(d)
        setIshistory(true)
      })
      setHistory(his)
    })
  }, [])
  return (
    <div className="flex flex-col items-center gap-6 w-full xl:w-3/5 m-auto py-16">
      <p className="darktextgreen text-2xl font-extrabold">
        User Account Details
      </p>
      <div className="flex md:flex-row flex-col gap-8 justify-between w-full">
        <div className="flex flex-col justify-right gap-2 sm:w-full justify-left">
          <span className="flex flex-row items-center gap-2">
            <p className="text-gray-600 text-sm">Name:</p>
            <h1 className="text-xl font-extrabold greentext">{username}</h1>
          </span>
          <span className="flex flex-row items-center gap-2">
            <p className="text-gray-600 text-sm">Email:</p>
            <h1 className="text-xl font-extrabold greentext">{email}</h1>
          </span>
          <span className="flex flex-row items-center gap-2">
            <p className="text-gray-600 text-sm">Balance:</p>
            <h1 className="text-xl font-extrabold greentext">{balance}</h1>
          </span>
          <span className="flex flex-row items-center gap-2">
            <p className="text-gray-600 text-sm">Up time:</p>
            <h1 className="text-xl font-extrabold greentext">{logontime}</h1>
          </span>
        </div>
        <div className="flex flex-col justify-right gap-2 sm:w-full justify-left">
          <div className="flex flex-row justify-between mb-4">
            <h1 className="text-xl font-extrabold">Recent five (5) updates</h1>

            <div className="flex relative">
              <div className="flex">
                <BiMessageDetail size={30} color="#" />
              </div>
              <div className="bg-red-500 rounded-lg h-4 w-4 absolute top-0 right-0"></div>
            </div>
          </div>

          <ul className="w-full">
            {ishistory === false ? (
              <li className="border-1 border-black py-2 px-4 mb-2  rounded-md w-full text-center flex flex-row justify-center">
                <p>Loading history</p>
              </li>
            ) : null}
            {history.length < 1 && ishistory === true ? (
              <li className="border-1 border-black py-2 px-4 mb-2 rounded-md w-full text-center flex flex-row justify-center">
                <p>No history</p>
              </li>
            ) : (
              history.map((e) => (
                <li
                  className="border-1 border-black py-2 px-4 mb-2 rounded-md w-full flex flex-col lg:flex-row justify-between gap-2"
                  key={e.date}
                >
                  <div className="flex">
                    <p>{e.history}</p>
                  </div>

                  {/* <div className="flex gap-4 items-center">
                    <ViewBtn obj={e} />
                    <FaDownload
                      style={{ cursor: 'pointer' }}
                      className="df"
                      onClick={() => downloadTxtFile(e)}
                    />
                  </div> */}
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
      <h1 className="text-xl text-center mt-5 mb-2 font-extrabold">
        Items bought
      </h1>
      <div className="flex md:flex-row flex-col gap-8 justify-between w-full p-6 border-1 border-gray-900 rounded-lg bg-white">
        <ul className="w-full">
          {ismycards === false ? (
            <li className="border-1 border-black py-2 px-4 mb-2  rounded-md w-full text-center flex flex-row justify-center">
              <p>Loading items</p>
            </li>
          ) : null}
          {mycards.length < 1 && ismycards === true ? (
            <li className="border-1 border-black py-2 px-4 mb-2 rounded-md w-full text-center flex flex-row justify-center">
              <p>No item found</p>
            </li>
          ) : (
            mycards.map((e) => (
              <li
                className="border-1 border-black py-2 px-4 mb-2 rounded-md w-full flex flex-col lg:flex-row justify-between gap-2"
                key={e.card}
              >
                <div className="flex">
                  <p>
                    Network: <span className="greentext">{e.network}</span>
                  </p>
                </div>
                <div className="flex">
                  <p>
                    Card: <span className="greentext">{e.card}</span>
                  </p>
                </div>
                <div className="flex">
                  <p>
                    Cvv: <span className="greentext">{e.cvv}</span>
                  </p>
                </div>
                <div className="flex">
                  <p>
                    Expiry: <span className="greentext">{e.expiry}</span>
                  </p>
                </div>
                <div className="flex">
                  <p>
                    Balance: <span className="greentext">{e.balance}</span>
                  </p>
                </div>
                <div className="flex gap-4 items-center">
                  <FaRecycle style={{ cursor: 'pointer' }} />
                  <ViewBtn obj={e} />
                  <FaDownload
                    style={{ cursor: 'pointer' }}
                    className="df"
                    onClick={() => downloadTxtFile(e)}
                  />
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  )
}

const ViewBtn = ({ obj }) => {
  const [coppied, setCoppied] = useState('Copy')
  const [open, setOpen] = useState(false)

  const handleClickToOpen = () => {
    setOpen(true)
  }

  const handleToClose = () => {
    setOpen(false)
  }

  return (
    <div stlye={{}}>
      <FaEye
        style={{ cursor: 'pointer' }}
        variant="outlined"
        color="primary"
        onClick={handleClickToOpen}
      />
      <Dialog open={open} onClose={handleToClose}>
        <DialogTitle>{obj.card}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Network: {obj.network}</p>
            <p>Card: {obj.card}</p>
            <p>Expiry: {obj.expiry}</p>
            <p>Cvv: {obj.cvv}</p>
            <p>Balance: {obj.balance}</p>
            <p>Price: {obj.price}</p>
            <p>Pin: {obj.pin}</p>
            <p>Bank: {obj.bank}</p>
            <p>Fullname: {obj.fullname}</p>
            <p>Email: {obj.email}</p>
            <p>Phone: {obj.phone}</p>
            <p>Address: {obj.address}</p>
            <p>Zip: {obj.zip}</p>
            <p>Country: {obj.country}</p>
            <p>State: {obj.state}</p>
            <p>City: {obj.city}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCoppied('Copy')
              handleToClose()
            }}
            color="danger"
            autoFocus
          >
            Close
          </Button>
          <Button
            onClick={() => {
              copy(obj.network).then((e) => {
                setCoppied('Coppied')
              })
            }}
            color="primary"
            autoFocus
          >
            {coppied}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    /*  <Buybtn func={handleOpen} variant="gradient" /> */
  )
}
const downloadTxtFile = (e) => {
  const element = document.createElement('a')
  const file = new Blob(
    [
      `Network: ${e.network}\n`,
      `Card: ${e.card}\n`,
      `Expiry: ${e.expiry}\n`,
      `Cvv: ${e.cvv}\n`,
      `Balance: ${e.balance}\n`,
      `Price: ${e.price}\n`,
      `Pin: ${e.pin}\n`,
      `Bank: ${e.bank}\n`,
      `Fullname: ${e.fullname}\n`,
      `Email: ${e.email}\n`,
      `Phone: ${e.phone}\n`,
      `Address: ${e.address}\n`,
      `Zip: ${e.zip}\n`,
      `Country: ${e.country}\n`,
      `State: ${e.state}\n`,
      `City: ${e.city}\n`,
    ],
    {
      type: 'text/plain',
    },
  )
  element.href = URL.createObjectURL(file)
  element.download = `${e.card}.txt`
  document.body.appendChild(element)
  element.click()
}

export default AccountPage

