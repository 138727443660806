import React from 'react'
import { useEffect, useState } from 'react'
import {
  Warning,
  HorizontalLine,
  NavView,
  TorWeblink,
  TableBoddy,
} from '../Components/home_elements'
import { isUser, getSingleUser } from '../firebase'
import moment from 'moment'
import { bnbfullz } from '../file'

import { FaSearch } from 'react-icons/fa'

const HomePage = () => {

  const [username, setUsername] = useState(localStorage.getItem('username'))
  const [useremail, setUseremail] = useState('')
  const [balance, setBalance] = useState(localStorage.getItem('balance'))
  const [uid, setUid] = useState('bin')
  const [password, setPassword] = useState('')
  const [isLogin, setIslogin] = useState(false)
  const [item, setItem] = useState('')
  const [checked, setChecked] = useState(false)

  document.title = 'Members Shop - Benumb Cards | #1 CC &amp; Fulls Shop'

  useEffect(() => {
   /*  var arr = []
    function gen(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const genrandom = (qty) => {
      var number = "";
      for (var i = 1; i <= qty; i++) {
        number = number + gen(0, 9)
      }
      return number
    }
    bnbfullz.forEach(v => {
      arr.push({
        network: v.network,
        card: v.card + '**********',
        cvv: v.cvv,
        pin: v.pin,
        expiry: v.expiry,
        bank: v.bank,
        balance: '$'+v.balance+gen(0, 9),
        fullname: v.fullname,
        email: v.email,
        phone: v.phone,
        address: v.address,
        zip: v.zip,
        country: v.country,
        state: v.state,
        city: v.city,
        price: '$'+v.price,
        availability: v.availability,


      })
    })
    console.log(arr) */

  

    isUser().then((v) => setIslogin(v))
  }, [])
  useEffect(() => {
    getSingleUser().then((v) => {
      //alert(v)
      setUsername(v.data().name)
      setUseremail(v.data().email)
      setBalance(v.data().balance)
      setPassword(v.data().password)
      setUid(v.data().id)
      localStorage.setItem('username', v.data().name)
      localStorage.setItem('balance', v.data().balance)
      localStorage.setItem('uid', v.data().id)
    })
  }, [])

  return (
    <div className="flex flex-col justify-center darkbg w-full ">
      <div className="flex flex-col justify-center  w-full px-4 lg:px-0">
        <Warning />
        <HorizontalLine />
        <div className="flex w-full justify-center flex-col items-center  lightbg pt-10">
          <NavView name={username} balance={balance} islogin={isLogin} />
          <TorWeblink />
        </div>
        {isLogin && (<div className="flex w-full xl:w-3/5 justify-center m-auto">
          <div className="flex lg:flex-row flex-col bg-white lg:justify-between justify-center gap-4 px-8 py-4 w-full overflow-x-scroll box-border">
            <div className="search relative lg:w-3/5 ">
              <input
                type=""
                name="search"
                id="search"
                className="px-3 py-2  w-full lightbg border-1 rounded-lg bg-whiteborder-gray-200 outline-0"
                placeholder="Search here..."
                value={item}
                onChange={(e) => {

                  setItem(e.target.value)
                }}
              />
              <div className="absolute top-3 bottom-0 right-3">
                <FaSearch className="" />
              </div>
            </div>
            <div className="flex gap-4 justify-center">
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  defaultChecked={checked}
                  onChange={() => {
                    checked && setUid('bin')
                    !checked && setUid('')
                    setChecked(!checked)
                  }}
                />
                <p>bin</p>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  defaultChecked={checked}
                  onChange={() => {
                    checked && setUid('network')
                    !checked && setUid('')
                    setChecked(!checked)
                  }}
                />
                <p>network</p>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  defaultChecked={checked}
                  onChange={() => {
                    checked && setUid('value')
                    !checked && setUid('')
                    setChecked(!checked)
                  }}
                />
                <p>value</p>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  defaultChecked={checked}
                  onChange={() => {
                    checked && setUid('exp')
                    !checked && setUid('')
                    setChecked(!checked)
                  }}
                />
                <p>exp</p>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  defaultChecked={checked}
                  onChange={() => {
                    checked && setUid('cvv')
                    !checked && setUid('')
                    setChecked(!checked)
                  }}
                />
                <p>cvv</p>
              </div>
            </div>
          </div>
        </div>)}
        <TableBoddy islogin={isLogin} item={item} uid={'uid'} />
      </div>
    </div>

  )
}

const Lastupdated = () => {
  const today = localStorage.getItem('today')
  const comparetoday = localStorage.getItem('comparetoday')
  if (today !== null) {
    if (comparetoday !== moment().format('LL').toString()) {
      localStorage.setItem('today', moment().format('LLL').toString())
    }
  } else {
    localStorage.setItem('today', moment().format('LLL').toString())
    localStorage.setItem('comparetoday', moment().format('LL').toString())
  }
}
Lastupdated()

export default HomePage
