import React from 'react'
import { useEffect, useState } from 'react'
import {
  Warning,
  HorizontalLine,
  NavView,
  TorWeblink,
  TableBoddy,
} from '../Components/home_elements'
import { isUser, getSingleUser, AddSupport } from '../firebase'
import {
  FaDollarSign,
  FaArrowRight,
  FaBitcoin,
  FaMonero,
  FaEthereum,
} from 'react-icons/fa'
const Support = () => {
  const [username, setUsername] = useState(localStorage.getItem('username'))
  const [useremail, setUseremail] = useState('')
  const [balance, setBalance] = useState(localStorage.getItem('balance'))
  const [uid, setUid] = useState('')
  const [password, setPassword] = useState('')
  const [isLogin, setIslogin] = useState(false)
  const [title, setTitle] = useState('Account')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  document.title = 'Support - BENUMB CC SHOP '

  const date = Date.now().toString()
  const read = 'close'

  useEffect(() => {
    isUser().then((v) => setIslogin(v))
  }, [])
  useEffect(() => {
    getSingleUser().then((v) => {
      //alert(v)
      setUsername(v.data().name)
      setUseremail(v.data().email)
      setBalance(v.data().balance)
      setPassword(v.data().password)
      setUid(v.data().id)
    })
  }, [])

  return (
    <div className="flex flex-col justify-center  w-full ">
      <div className="flex flex-col justify-center w-full px-4 lg:px-0">
        <Warning />
        <HorizontalLine />
        <div className="flex w-full justify-center flex-col items-center lightbg pt-10">
          <NavView name={username} balance={balance} islogin={isLogin} />
          <br />
        </div>
        <div className="flex justify-center items-center w-full">
          <form action="" className="flex flex-col p-8 gap-4 lg:w-2/4 w-full outline-0">
            <p className="text-center text-green-900 text-lg font-bold">
              {status}
            </p>
            <p className="text-black">Issue</p>
            <select
              name=""
              id=""
              required
              className="p-2 supportbg  border-1 border-gray-400 outline-0"
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              value = {title}
            >
              <option value="Account">Account</option>
              <option value="Payment">Payment</option>
              <option value="Product">Product</option>
              <option value="Other">Other</option>
            </select>
            <p className="">Subject</p>
            <input
              className="p-2 supportbg border-1 border-gray-400 outline-0"
              type="text"
              name="subject"
              id="subject"
              placeholder="Subject"
              required
              onChange={(e) => {
                setSubject(e.target.value)
              }}

              value = {subject}
            />
            <textarea
              className="p-2 supportbg border-1 border-gray-400 h-72 outline-0"
              name=""
              id=""
              placeholder="Please describe your issue"
              required
              onChange={(e) => {
                setMessage(e.target.value)
              }}
              value = {message}
            ></textarea>
            <button
              className="p-3 supportbg text-white text-lg outline-0"
              type="submit"
              style={{ background: '#0F5774' }}
              onClick={(e) => {
                e.preventDefault()
                console.log(title, subject, message)
                var d = {
                  title,
                  subject,
                  date,
                  message,
                  useremail,
                  username,
                  read,
                }
                AddSupport(d).then((e) => {
                  setStatus('Your compliant has been recieved successfully. Expect reply in less than 24 hours')
                  setMessage('')
                  setSubject('')
                  setTitle('Account')
                  setTimeout(() => {
                    setStatus('')
                  }, 10000)
                })
              }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Support
