import React from 'react'
import ReactDom from 'react-dom'
import './index.css'
import { ThemeProvider } from '@material-tailwind/react'
import { ContextProvider } from './Contexts/ContextProvider'

import App from './App'

ReactDom.render(
  <ContextProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
  </ContextProvider>,
  document.getElementById('root'),
)
