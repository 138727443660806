import React, { useState } from 'react'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import '../App.css'
import { NavLink } from 'react-router-dom'
import { login } from '../firebase'
const Login = () => {
  const [email, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [vpass, setVpass] = useState(false)
  document.title = "Log In - Benumb Cards | #1 Easy Cashout CC & Fulls Shop"
  return (
    <div className="flex w-full h-screen loginbackground p-2 justify-center">
      <div className="w-full mt-20 lg:w-2/4 xl:w-1/3 flex justify-center">
        <div className="w-full ">
          <img
            className="flex w-72 m-auto"
            src="https://benumb.cards/wp-content/uploads/2022/05/1ptv6440.png"
            alt="Benumb"
          />

          <input
            type="email"
            name="username"
            id="username"
            className="outline:none p-2 w-full logindeco mt-5"
            placeholder="Username or Email"
            onChange={(e) => setUsername(e.target.value)}
          />
          {/*  <input
            type="email"
            name="email"
            id="email"
            className="outline:none p-2 w-full logindeco mt-5"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />*/}
          <div className="flex flex-col relative mt-5 ">
            <input
              type={vpass ? 'text' : 'password'}
              name="pass-1"
              id="pass-1"
              className="outline:none p-2 w-full logindeco relative"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute top-2 bottom-0 right-2"
              onClick={(e) => {
                setVpass(!vpass)
              }}
            >
              {vpass ? (
                <AiOutlineEyeInvisible size={25} />
              ) : (
                <AiOutlineEye size={25} />
              )}
            </div>
          </div>

          <div className="flex items-center gap-1 mt-2">
            <input type="checkbox" name="check" id="" />
            <p className="text-black">Remember Me</p>
          </div>

          <button
            className="sbtn p-2 w-full green mt-2 mb-3 font-normal text-lg text-white flex justify-center"
            onClick={() => {
              login(email, password).then((v) => {
                localStorage.setItem('email', email)
                window.location.replace("/members-shop")
              })
            }}
          >
            Log In
          </button>
          <div className="flex items-center gap-1 mb-8">
            <p className="text-black">Lost your password?</p>
          </div>

          <p className="justify-left text-lg">
            Need an account?{' '}
            <NavLink to="/sign-up">
              {
                <span className="font-bold greentext ">
                  <strong>Sign Up.</strong>
                </span>
              }
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Login
