import {
  FaCreditCard,
  FaComment,
  FaTicketAlt,
  FaCoins,
  FaUserCircle,
  FaBars,
} from 'react-icons/fa'
import { Table } from './tablecomponent'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { Buybtn } from '../Data/data'
import cardicon from '../assets/images/cardicon.png'
import toricon from '../assets/images/tor.png'

import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'

import DialogContent from '@material-ui/core/DialogContent'

import { useNavigate } from 'react-router-dom'

const Warning = () => {
  return (
    <div className="flex w-full justify-center items-center lightbg py-6">
      <div className="flex w-full xl:w-3/5 darkerror darkredborder items-center justify-between py-2 px-4">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-exclamation-triangle-fill justify-self-center"
            viewBox="0 0 16 16"
          >
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
        </div>
        <div className="flex justify-center flex-1">
          <h1 className="text-black lg:text-lg md:text-sm text-xs font-medium text-center">
            WARNING: Please be cautious and avoid phishing sites! Click here to
            confirm you are on the correct Benumb.cards!
          </h1>
        </div>
      </div>
    </div>
  )
}

const HorizontalLine = () => {
  return <hr className="border-gray-400" />
}

const NavView = ({ name, balance, islogin }) => {
  const [islogoutbtnup, setIslogoutbtnup] = useState(false)
  const [isactlogoutbtnup, setIsactlogoutbtnup] = useState(false)
  const [isMenu, setIsMenu] = useState(false)
  const Nav = useNavigate()
  const pr = 50
  return (
    <div className="flex w-full xl:w-3/5 items-center justify-between py-1 relative">
      <div className="flex flex-wrap justify-center">
        <NavLink to="/">
          <img
            style={{ width: 250 }}
            className="max-w-xs"
            /* src="https://benumb.cards/wp-content/uploads/2022/05/1ptv6440.png" */
            src={cardicon}
            alt="cc to btc"
          />
        </NavLink>
      </div>

      <div className="hidden w-full md:block">
        <div className="flex gap-6 justify-end flex-wrap">
          <div className="flex items-center">
            <NavLink to="/members-shop" className="flex items-center gap-4 ">
              <FaCreditCard color="#2C3E54" style={{ width: 20, height: 20 }} />
              <span>
                <p
                  color="#2C3E54"
                  className="text-lg "
                  style={{ color: '#2C3E54' }}
                >
                  Shop
                </p>
              </span>
            </NavLink>
          </div>

          <div className="flex items-center">
            <NavLink to="/forum" className="flex items-center gap-4 ">
              <div className="flex items-center gap-4 ">
                <FaComment color="#2C3E54" style={{ width: 20, height: 20 }} />{' '}
                <span>
                  <p
                    color="#2C3E54"
                    className="text-lg"
                    style={{ color: '#2C3E54' }}
                  >
                    Forum
                  </p>
                </span>
              </div>
            </NavLink>
          </div>
          <div className="flex items-center">
            <NavLink to="/support" className="flex items-center gap-4 ">
              <div className="flex items-center gap-4">
                <FaTicketAlt
                  color="#2C3E54"
                  style={{ width: 20, height: 20 }}
                />{' '}
                <span>
                  <p
                    color="#2C3E54"
                    className="text-lg"
                    style={{ color: '#2C3E54' }}
                  >
                    Support
                  </p>
                </span>
              </div>
            </NavLink>
          </div>

          <div className="flex">
            <div className="flex items-center">
              <div
                className="flex items-center gap-4 cursor-pointer"
                onClick={() => Nav('/wallet', { state: { pr } })}
              >
                <div className="darkgreen h-14 rounded-l flex items-center justify-center gap-4 font-bold p-4">
                  <FaCoins color="#fff" style={{ width: 20, height: 20 }} />{' '}
                  <span>
                    <p color="#fff" className="text-xl text-white">
                      {islogin ? `${balance}` : '$0.00'}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <NavLink
                to={islogin ? '/account' : '/login'}
                className="flex items-center gap-4 relative"
              >
                <div
                  className="darkblue h-14 rounded-r flex items-center justify-center gap-4 px-4"
                  onMouseOver={() => {
                    islogin ? setIslogoutbtnup(true) : setIslogoutbtnup(false)
                    console.log('Mouse over movement detected')
                  }}
                  onMouseOut={() => {
                    setTimeout(() => {
                      if (!isactlogoutbtnup) setIslogoutbtnup(false)
                    }, 2000)
                  }}
                  onClickCapture={() => {
                    // islogin ? setIslogoutbtnup(true) : setIslogoutbtnup(false)
                  }}
                >
                  <FaUserCircle
                    color="#fff"
                    style={{ width: 20, height: 20 }}
                  />{' '}
                  <p color="#fff" className="text-lg text-white">
                    {islogin ? name : 'Log In'}
                  </p>
                </div>

                {islogoutbtnup ? (
                  <div className="absolute top-16 right-16">
                    <button
                      className="py-4 px-6 darkblue text-white"
                      onClick={() => {
                        window.location.href = '/logout_from_account'
                      }}
                      onMouseOver={() => {
                        setIsactlogoutbtnup(true)
                        setIslogoutbtnup(true)
                      }}
                      onMouseOut={() => {
                        setIslogoutbtnup(false)
                        setIsactlogoutbtnup(false)
                      }}
                    >
                      Log out
                    </button>
                  </div>
                ) : null}
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex gap-2 md:hidden pr-4"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIsMenu(!isMenu)
        }}
      >
        <p className="text-sm">Menu</p>
        <FaBars
          color="#2C3E54"
          style={{ width: 20, height: 20, cursor: 'pointer' }}
        />
      </div>
      {isMenu === true ? (
        <div className=" green w-full md:hidden absolute top-24 z-10 p-4">
          <div className="flex gap-6 justify-center flex-wrap">
            <div className="flex items-center">
              <NavLink to="/members-shop" className="flex items-center gap-4 ">
                <FaCreditCard
                  color="#2C3E54"
                  style={{ width: 20, height: 20 }}
                />
                <span>
                  <p
                    color="#2C3E54"
                    className="text-lg "
                    style={{ color: '#2C3E54' }}
                  >
                    Shop
                  </p>
                </span>
              </NavLink>
            </div>

            <div className="flex items-center">
              <NavLink to="/forum" className="flex items-center gap-4 ">
                <div className="flex items-center gap-4 ">
                  <FaComment
                    color="#2C3E54"
                    style={{ width: 20, height: 20 }}
                  />{' '}
                  <span>
                    <p
                      color="#2C3E54"
                      className="text-lg"
                      style={{ color: '#2C3E54' }}
                    >
                      Forum
                    </p>
                  </span>
                </div>
              </NavLink>
            </div>
            <div className="flex items-center">
              <NavLink to="/support" className="flex items-center gap-4 ">
                <div className="flex items-center gap-4">
                  <FaTicketAlt
                    color="#2C3E54"
                    style={{ width: 20, height: 20 }}
                  />{' '}
                  <span>
                    <p
                      color="#2C3E54"
                      className="text-lg"
                      style={{ color: '#2C3E54' }}
                    >
                      Support
                    </p>
                  </span>
                </div>
              </NavLink>
            </div>

            <div className="flex">
              <div className="flex items-center">
                <div
                 
                  className="flex items-center gap-4 "
                  onClick={() => Nav('/wallet', { state: { pr } })}
                >
                  <div className="darkgreen h-14 rounded-l flex items-center justify-center gap-4 font-bold p-4">
                    <FaCoins color="#fff" style={{ width: 20, height: 20 }} />{' '}
                    <span>
                      <p color="#fff" className="text-xl text-white">
                        {islogin ? `${balance}` : '$0.00'}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <NavLink
                  to={islogin ? '/account' : '/login'}
                  className="flex items-center gap-4 relative"
                >
                  <div
                    className="darkblue h-14 rounded-r flex items-center justify-center gap-4 px-4"
                    onMouseOver={() => {
                      islogin ? setIslogoutbtnup(true) : setIslogoutbtnup(false)
                      console.log('Mouse over movement detected')
                    }}
                    onMouseOut={() => {
                      setTimeout(() => {
                        if (!isactlogoutbtnup) setIslogoutbtnup(false)
                      }, 2000)
                    }}
                    onClickCapture={() => {
                      // islogin ? setIslogoutbtnup(true) : setIslogoutbtnup(false)
                    }}
                  >
                    <FaUserCircle
                      color="#fff"
                      style={{ width: 20, height: 20 }}
                    />{' '}
                    <p color="#fff" className="text-lg text-white">
                      {islogin ? name : 'Log In'}
                    </p>
                  </div>

                  {islogoutbtnup ? (
                    <div className="absolute top-16 right-16">
                      <button
                        className="py-4 px-6 darkblue text-white"
                        onClick={() => {
                          window.location.href = '/logout_from_account'
                        }}
                        onMouseOver={() => {
                          setIsactlogoutbtnup(true)
                          setIslogoutbtnup(true)
                        }}
                        onMouseOut={() => {
                          setIslogoutbtnup(false)
                          setIsactlogoutbtnup(false)
                        }}
                        onClickCapture={() => {}}
                      >
                        Log out
                      </button>
                    </div>
                  ) : null}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const TorWeblink = () => {
  return (
    <div className="flex w-full justify-center items-center lightbg mt-8">
      <div className="flex w-full xl:w-3/5 semibg bordergreen items-center gap-2 justify-center">
        <div className="flex justify-center flex-1 items-center gap-2 px-4 py-4">
          <img src={toricon} alt="Benumb" style={{ width: 20, height: 20 }} />
          <h1 className="text-black lg:text-lg md:text-sm text-xs font-medium text-center">
            Benumb Onion Address:&nbsp;
            <span className="vcolor" style={{ wordBreak: 'break-all' }}>
              {' '}
              benumbiernqlud55izbw4mdubush4zhzpg4rw3c2j6ew3ggpzbb7gdqd.onion
            </span>
          </h1>
        </div>
      </div>
    </div>
  )
}

const TableBoddy = ({ islogin, item, uid }) => {
  Tablealive()

  return (
    <div className="flex w-full justify-center relative">
      <div className="flex w-full xl:w-3/5  gap-12 box-border overflow-x-scroll">
        <Table item={item} uid={uid} />
        {!islogin ? <LoginDialog /> : null}
      </div>
    </div>
  )
}

const LoginDialog = () => {
  return (
    <div className="flex w-full justify-center items-center absolute left-0 right-0">
      <div className="flex w-full xl:w-3/5 bg-white bordergreen items-center justify-center lg:px-36 sm:mx-10 py-8">
        <div className="flex items-center justify-center flex-col py-45 gap-6">
          <h1 className="px-4 gcolor text-2xl font-bold">
            Welcome to the Benumb CC Shop
          </h1>
          <p className="px-4 gcolor text-lg">
            Benumb is the #1 carding shop. We offer high value non vbv cards,
            fulls, drops, and more. Join us to discover for yourself why Benumb
            is the most recommended cc shop and why we are trusted by carders
            worldwide!
          </p>
          <NavLink
            to="/sign-up"
            className="green text-white text-center rounded text-xl py-3 w-3/4"
          >
            <button type="button">Join Now</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

const Tablealive = () => {
  const table = document.querySelector('td')
  let tb = setInterval(() => {
    if (table !== null) {
      for (var i = 1; i <= 20; i++) {
        document
          .querySelector(
            `#gridcomp_content_table > tbody > tr:nth-child(${i}) > td:nth-child(5)`,
          )
          .classList.add('gcolor')
      }
      clearInterval(tb)
    }
  }, 1000)
}

const Loading = () => {
  const [open, setOpen] = useState(false)

  const handleClickToOpen = () => {
    setOpen(true)
  }

  const handleToClose = () => {
    setOpen(false)
  }
  return (
    <div stlye={{}}>
      <Buybtn
        variant="outlined"
        color="primary"
        func={handleClickToOpen}
      ></Buybtn>
      <Dialog open={open} onClose={handleToClose}>
        <DialogContent>
          <DialogContentText>Loading</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export { Warning, HorizontalLine, NavView, TorWeblink, TableBoddy }
