import { useState, useEffect } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  Inject,
  Pager,
} from '@syncfusion/ej2-react-grids'
import { colm, data } from '../Data/data'
import { bnbfullz, bnbcards } from '../file'
import { useMediaPredicate } from 'react-media-hook'

export const Table = (item, uid) => {
  const getRandomItem = (arr) => {
    var ar = []
    for (var i = 0; i < 200; i++) {
      const randomIndex = Math.floor(Math.random() * arr.length);
      const item = arr[randomIndex];
      ar.push(item)
    }

    return ar;
  }
  const flist = () => {
    if (item.item !== '') {
      return getRandomItem(bnbfullz).filter((e) =>
        e.network.toLowerCase().includes(item.item.toLowerCase()),
      )
    }/*  else if (item.item !== '' && uid.uid === 'network') {
      return bnbcards.filter((e) =>
        e.network.toLowerCase().includes(item.item.toLowerCase()),
      )
    } else if (item.item !== '' && uid.uid === 'value') {
      return bnbcards.filter((e) =>
        e.price.toLowerCase().includes(item.item.toLowerCase()),
      )
    } else if (item.item !== '' && uid.uid === 'exp') {
      return bnbcards.filter((e) =>
        e.expiry.toLowerCase().includes(item.item.toLowerCase()),
      )
    } */ else {
      return getRandomItem(bnbfullz)
    }
  }
  //console.log('F-List:',  flist())
  return (
    <div className="flex w-full">
      {/* <Header category="Page" title="Benumb Cards" /> */}
      <GridTable data={flist()} />
      {/*  {small && <GridTable width={400}/>} */}
    </div>
  )
}

const GridTable = ({ data }) => {
  const pageSettings = { pageSize: 50 }
  return (
    <GridComponent
      id="gridcomp"
      dataSource={data}
      allowPaging={true}
      allowSorting={true}
      pageSettings={pageSettings}
    >
      <ColumnsDirective className="">
        {colm.map((e, i) => (
          <ColumnDirective key={i} {...e} />
        ))}
      </ColumnsDirective>
      <Inject services={[Resize, Sort, ContextMenu, Filter, Page]} />
    </GridComponent>
  )
}
