import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css'
//import { useStateContext } from './Contexts/ContextProvider'
import HomePage from './Pages/homepage'
import AccountPage from './Pages/account'
import Signup from './Pages/signup'
import Login from './Pages/login'
import Support from './Pages/support'
import Forum from './Pages/forum'
import Payment from './Pages/payment'
import FourOFour from './Pages/fofpage'
import ShopPage from './Pages/shop'
import ConfirmLogout from './Pages/logout'


const App = () => {
  return (
    <div>
      <BrowserRouter>
        <div>
          {/* Routes */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/shop" element={<HomePage />} />
            <Route path="/logout_from_account" element={<ConfirmLogout />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/members-shop" element={<ShopPage />} />
            <Route path="/benumb" element={<HomePage />} />
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/support" element={<Support />} />
            <Route path="/forum" element={<Forum />} />
            <Route path="/wallet" element={<Payment />} />
            <Route path="*" element={<FourOFour />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  )
}

export default App

/* ,
    "rewrites": [
      {
        "source": "**",
        "destination": "/index.html"
      }
    ] 
*/