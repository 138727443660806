import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth'
import {
  getFirestore,
  query,
  getDocs,
  getDoc,
  collection,
  where,
  addDoc,
  setDoc,
  limit,
  doc,
  updateDoc,
} from 'firebase/firestore'

import { bnbcards, bnbfullz } from './file'

const firebaseConfig = {
  apiKey: 'AIzaSyBwVwmm-oAH3FR3N4PApFyEFhI_A-ARNXM',
  authDomain: 'benumb-df006.firebaseapp.com',
  projectId: 'benumb-df006',
  storageBucket: 'benumb-df006.appspot.com',
  messagingSenderId: '314980092530',
  appId: '1:314980092530:web:4c12d2a43151b69a2310eb',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (error) {
    console.error(error)
    alert(error.message)
  }
}
export const register = async (username, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    const data = doc(db, 'users', email)

    await setDoc(data, {
      id: user.uid,
      name: username,
      email: email,
      password: password,
      balance: '$0.00',
    })
  } catch (error) {
    console.error(error)
    alert(error.message)
  }
}

export const resetPasss = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert('Check your email, ', email, ' for the link to reset your password')
  } catch (error) {
    console.error(error)
    alert(error.message)
  }
}

export const logout = async () => {
 await signOut(auth)
}

export const isUser = async () => {
  if (localStorage.getItem('email') !== null) {
    return true
  }
  return false
}

export const getSingleUser = async () => {
  const email = localStorage.getItem('email')
  const docref = doc(db, 'users', email)
  return await getDoc(docref)
}

export const updateBalance = async (price) => {
  const email = localStorage.getItem('email')
  const balance = parseInt(localStorage.getItem('balance').split('$')[1])
  if (balance > price) {
    var rembalance = parseInt(balance) - parseInt(price)
    localStorage.setItem('balance', `$${rembalance}`)
    return await updateDoc(doc(db, 'users', email), {
      balance: `$${rembalance}`,
    })
  } else {
    throw new Error('Balance is not sufficient')
  }
}
export const getkratos = async () => {
  const docref = doc(db, 'admin', 'kratos')
  return await getDoc(docref)
}
export const updateUserPayment = async (amount) => {
  const email = localStorage.getItem('email')
  const balance = parseInt(localStorage.getItem('balance').split('$')[1])

  return await updateDoc(doc(db, 'users', email), {
    balance: `$${amount + balance}`,
  })
}




function gen(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const genrandom = (qty) => {
  var number = "";
  for (var i = 1; i <= qty; i++) {
    number = number + gen(0, 9)
  }
  return number
}






export const Preparefullz = async (data) => {
  var datas = {};
  const uid = localStorage.getItem('email')

  var splitcards = data.card.split('*')
  /* var splitcvvs = data.cvv.split("*")
  var card = `${splitcards[0]}${genrandom(6)}${splitcards[(splitcards.length) - 1]}`
  var cvv = `${genrandom(2)}${splitcvvs[(splitcvvs.length) - 1]}` */
  var rand = gen(0, 4000)

  datas = {
    id: uid,
    network: data.network,
    card: splitcards[0]+genrandom(10),
    expiry: data.expiry,
    cvv: data.cvv,
    balance: data.balance,
    price: data.price,
    pin: bnbfullz[rand].pin,
    bank: bnbfullz[rand].bank,
    fullname: bnbfullz[rand].fullname,
    email: bnbfullz[rand].email,
    phone: bnbfullz[rand].phone,
    address: bnbfullz[rand].address,
    zip: bnbfullz[rand].zip,
    country: bnbfullz[rand].country,
    state: bnbfullz[rand].state,
    city: bnbfullz[rand].city,
  }


  setDoc(doc(db, "boughtcards", datas.card), datas)

}

export const AddHistory = async (obj) => {
  const date = Date.now().toString();
  setDoc(doc(db, 'history', date), obj)
}
export const AddSupport = async (obj) => {
  
  setDoc(doc(db, 'support', obj.date), obj)
}


//PrepareData()

export const GetHistory = async () => {

  const uid = localStorage.getItem('email')
  const q = query(collection(db, 'history'),limit(5), where('uid', '==', uid))
  return await getDocs(q)
}

export const GetCards = async () => {

  const uid = localStorage.getItem('email')
  const q = query(collection(db, 'boughtcards'), where('id', '==', uid))
  return await getDocs(q)
}



