import { useState, useEffect } from 'react'
import { FaCaretDown, FaTag, FaCartPlus } from 'react-icons/fa'
import { BsBookmark } from 'react-icons/bs'
import { isUser, updateBalance } from '../firebase'

import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { bnbcards, bnbfullz } from '../file'
import { Preparefullz, AddHistory } from '../firebase'
import { useNavigate } from 'react-router-dom'




const ButtonComp = (prop) => {
  const [lg, setLg] = useState(true)

  useEffect(() => {
    isUser().then((v) => setLg(v))
  }, [])
  const [open, setOpen] = useState(false)



  const handleClickToOpen = () => {
    setOpen(true)
  }

  const handleToClose = () => {
    setOpen(false)
  }
const sendprice = useNavigate()
  return (
    <div stlye={{}}>
      <Buybtn
        variant="outlined"
        color="primary"
        func={handleClickToOpen}
      ></Buybtn>
      <Dialog open={open} onClose={handleToClose}>
        <DialogTitle>{'Benumb Cards - Buy Card'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to buy this {prop.Network} card?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToClose} color="danger" autoFocus>
            Close
          </Button>
          <Button
            onClick={() => {
              handleToClose()
              if (!lg) {
                window.location.replace('/login')
              } else {
                BuyProcess(prop.price, prop.index, sendprice)
              }
            }}
            color="primary"
            autoFocus
          >
            Continue, Buy
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    /*  <Buybtn func={handleOpen} variant="gradient" /> */
  )
}

export const Buybtn = ({ func }) => {
   
  return (
    <div className="flex items-center justify-between" color="#16a085">
      {/* <div className="flex gap-2">
        <FaCaretDown style={{ width: '16px', height: '16px' }} />
        <FaTag style={{ width: '16px', height: '16px' }} />
        <BsBookmark style={{ width: '16px', height: '16px' }} />
      </div> */}
      <button
        className="py-2 px-8 bordergreen buybg"
        style={{ border: '1px #16a085 solid' }}
        onClick={func}
      >
        <FaCartPlus
          color="#16a085"
          style={{ width: '18px', height: '18px' }}
          onClick={() => { }}
        />
      </button>
    </div>
  )
}

const BuyProcess = (price, index, sendprice) => {
  const balance = localStorage.getItem('balance')
  const email = localStorage.getItem('email')

 
  var pr = parseInt(price.split('$')[1]).toFixed(0)
  if (isUser()) {
    /* if(pr < 50){

      return
    } */
    if (pr < parseInt(balance.split('$')[1])) {
      console.log('amount can make purchase')
      updateBalance(pr)
        .then((value) => {
          Preparefullz(bnbfullz[index]).then((v) => {
            window.location.reload()
            AddHistory({
              uid: email,
              date: Date.now().toString(),
              history: `You bought a ${bnbfullz[index].network} card ${bnbfullz[index].card} for ${bnbfullz[index].price}`
            })
          })

        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      console.log('Top up account balance: account balance is: ', balance)
      sendprice('/wallet', { state: { pr } })
    }
  } else {
    window.location.href = "/login"
  }
}

export const colm = [
  {
    headerText: 'NETWORK',
    field: 'network',
    textAlign: 'Left',
    fontWeight: 'Bold',
    width: '150'
  },
  {
    headerText: 'BIN',
    field: 'card',
    textAlign: 'Left',
    fontWeight: 'Bold',
    width: '100'
  },
  {
    headerText: 'Country',
    field: 'country',
    textAlign: 'Left',
    fontWeight: 'Bold',
    width: '150'
  },
  {
    headerText: 'Expiry',
    field: 'expiry',
    textAlign: 'Left',
    fontWeight: 'Bold',
    width: '100'
  },
  {
    headerText: 'CVV',
    field: 'cvv',
    textAlign: 'Left',
    fontWeight: 'Bold',
    width: '100'
  },
  {
    headerText: 'Balance',
    field: 'balance',
    textAlign: 'Left',
    fontWeight: 'Bold',
    width: '100',
    color: '#16a085'
  },
  {
    headerText: 'Price',
    field: 'price',
    textAlign: 'Left',
    fontWeight: 'Bold',
    width: '100',
    color: 'green'
  },
  {
    headerText: 'Buy',
    template: ButtonComp,
    width: '100',
    textAlign: 'Left',
    fontWeight: 'Bold',

  },
]

export const PaymentAPI = async (kratos, amt) => {
  const kratosFath = 'https://api.commerce.coinbase.com/charges'


  const name = localStorage.getItem('username')
  const email = localStorage.getItem('email')
  const uid = localStorage.getItem('uid')
  const desc = 'Benumb payment for non vbv credit cards with high balance'
  const version = '2018-03-22'
  console.log('Kratos:', kratos)
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('X-CC-Api-Key', kratos)
  myHeaders.append('X-CC-Version', version)

  var raw = JSON.stringify({
    name: 'BENUMB CLONE',
    description: desc,
    local_price: {
      amount: amt,
      currency: 'USD',
    },
    pricing_type: 'fixed_price',
    metadata: {
      customer_id: uid,
      customer_name: name,
      customer_email: email,
    },
    redirect_url: 'https://charge/completed/page',
    cancel_url: 'https://charge/canceled/page',
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }

  return fetch('https://api.commerce.coinbase.com/charges', requestOptions)
  /* .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log('error', error)) */
}

export const CheckPaymentAPI = async (kratos, code) => {
  const kratosFath = 'https://api.commerce.coinbase.com/charges/' + code
  const version = '2018-03-22'
  var myHeaders = new Headers()
  myHeaders.append('X-CC-Api-Key', kratos)
  myHeaders.append('X-CC-Version', version)

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  return fetch(kratosFath, requestOptions)
}
//CVV9FE3Z
//2ADFYHCM


/* var arr = []
const item = document.querySelector("#footable_2683 > tbody").innerText.split('\t').toString().split('\n')
for(var i = 0; i < item.length-1; i++){
var a =item[i].toString().split(',')
    arr.push(
        {"network":a[0],
        "card":a[1],
        "expiry":a[2],
        "cvv":a[3],
        "balance":a[4],
        "price":a[5]}
    )

}
console.log(arr) */
export const ShowDialog = ({ Btn, message, func, actionmessage }) => {
  const [open, setOpen] = useState(false)



  const handleClickToOpen = () => {
    setOpen(true)
  }

  const handleToClose = () => {
    setOpen(false)
  }

  return (
    <div stlye={{}}>
      <Btn
        variant="outlined"
        color="primary"
        func={handleClickToOpen}
      ></Btn>
      <Dialog open={open} onClose={handleToClose}>
        <DialogTitle>{'Benumb Cards - Buy Card'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToClose} color="danger" autoFocus>
            Close
          </Button>
          <Button
            onClick={() => {
              handleToClose()
              func()
            }}
            color="primary"
            autoFocus
          >
            {actionmessage}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    /*  <Buybtn func={handleOpen} variant="gradient" /> */
  )
}

/* export const AlertDialog = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <div className='mx-auto p-4 max-w-md rounded-lg border-1 border-gray-400 bg-white w-ful m-6 lg:w-max lg:h-max ' style={{ zIndex: '99999' }}>
                    <h1 className='font-bold text-lg mb-5'>{list.title}</h1>

                    <p className='mb-3'>{list.msg}</p>
                    <div className="flex gap-4 justify-end p-2">

                        <button className='font-medium' onClick={onClose}>{list.nbtn}</button>
                        {list.conf && (
                            <button
                                className='font-bold'
                                onClick={() => {
                                    list.action();
                                    onClose();
                                    onClose();
                                }}
                            >
                                {list.ybtn}
                            </button>)}
                    </div>
                </div>
            );
        }
    });
} */